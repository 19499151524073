import {Icon} from '@iconify/react';
import {sentenceCase} from 'change-case';
import * as React from 'react';
import {Component, useEffect} from 'react';
// material
import {
    Box,
    Card,
    Checkbox,
    Container, Grid,
    IconButton,
    Input,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    ToggleButton,
    Typography
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import {UserListHead, UserListToolbar} from '../components/_dashboard/user';
//
import * as CommunicationService from "../services/CommunicationService";
import downloadOutlined from "@iconify/icons-ant-design/download-outlined";
import deletedFileOutlined from "@iconify/icons-ant-design/delete-row-outlined";
import * as LogicService from "../services/LogicService";
import {STATUSES} from "../services/LogicService";
import * as AlertMapper from "../services/AlertMapper";
import AccessSelect from "./AccessSelect";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import OrderMoreMenu from "../components/_dashboard/user/OrderMoreMenu";
import * as Languages from "../constants/Languages";
import * as Countries from "../constants/Countries";
import ReportToolbar from "../components/_dashboard/user/ReportToolbar";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {fDateTimeForBackend} from "../utils/formatTime";
import AppInfoOne from "../components/authentication/order/AppInfoOne";
import Constants from "../Constants";
import AppInfoTwo from "../components/authentication/order/AppInfoTwo";
import AppInfoThree from "../components/authentication/order/AppInfoThree";
import "./Orders.css"
// ----------------------------------------------------------------------

const TABLE_HEAD_FOR_LAWYER = [
    {id: 'id', label: 'Id', alignRight: false},
    {id: 'name', label: 'Nume', alignRight: false},
    {id: 'userType', label: 'Tip', alignRight: false},
    {id: 'status', label: 'Status', alignRight: false},
    {id: 'createdAt', label: 'Creata', alignRight: false},
    {id: 'idCard', label: 'ID', alignRight: false},
    {id: 'bussinessContract', label: 'Imp. SRL', alignRight: false},
    {id: 'registrationCertificate', label: 'C. Inreg.', alignRight: false},
    {id: 'anexa38', label: 'Anexa 38', alignRight: false},
    {id: 'details', label: 'Detalii', alignRight: false},
    {id: ''}
]

const TABLE_HEAD = [
    {id: 'id', label: 'Id', alignRight: false},
    {id: 'name', label: 'Nume', alignRight: false},
    {id: 'userType', label: 'Tip', alignRight: false},
    {id: 'status', label: 'Status', alignRight: false},
    {id: 'price', label: 'Pret', alignRight: false},
    {id: 'createdAt', label: 'Creata', alignRight: false},
    {id: 'asigned', label: 'Atribuire Acces', alignRight: false},
    {id: 'idCard', label: 'ID', alignRight: false},
    {id: 'personWithId', label: 'Person', alignRight: false},
    {id: 'bussinessContract', label: 'Imp. SRL', alignRight: false},
    {id: 'registrationCertificate', label: 'C. Inreg.', alignRight: false},
    {id: 'anexa38', label: 'Anexa 38', alignRight: false},
    {id: 'lawyerContract', label: 'Contract', alignRight: false},
    {id: 'policeRecord', label: 'Cazier', alignRight: false},
    {id: 'policeRecordTranslated', label: 'Cazier Tradus', alignRight: false},
    {id: 'paymentProof', label: 'Dovada platii', alignRight: false},
    {id: 'details', label: 'Detalii', alignRight: false},
    {id: ''}
];

export default class Orders extends Component {

    constructor() {
        super()
        this.state = {
            detailsModalIsOpen: false,
            tableHead: TABLE_HEAD,
            isLawyer: true,
            orders: [],
            urgentOrders: [],
            laterAddedDeliveryOrders: [],
            lawyers: [],
            statuses: [],
            transactions: [
                {
                    label: 'Verified',
                    value: true
                },
                {
                    label: 'Not verified',
                    value: false
                }
            ],
            page: 0,
            order: 'desc',
            selected: [],
            orderBy: 'createdAt',
            filterBy: null,
            filterName: null,
            filterLawyer: null,
            filterStatus: null,
            type: 'ALL',
            filterTransaction: null,
            rowsPerPage: 10,
            totalItems: 0,
            totalPages: 0
        }
    }

    setOrders = (val) => {
        this.setState({orders: val})
    }

    setUrgentOrders = (val) => {
        this.setState({urgentOrders: val})
    }

    setLaterAddedDeliveryOrders = (val) => {
        this.setState({laterAddedDeliveryOrders: val})
    }

    setDetailsModalIsOpen = (val) => {
        this.setState({detailsModalIsOpen: val})
    }

    setPage = (val) => {
        this.setState({page: val}, () => this.getOrders(true))
    }

    setOrder = (val) => {
        this.setState({order: val}, () => this.getOrders(true))
        // this.setState({order: val})
    }

    setSelected = (val) => {
        this.setState({selected: val})
    }

    setOrderBy = (val) => {
        this.setState({orderBy: val}, () => {
            // this.getOrders()
        })
    }

    setFilterName = (type, val) => {
        this.clearAllFilters(
            () => {
                this.setState({
                    filterBy: type,
                    filterName: val
                }, () => this.getOrders(true, type, val))
            }
        )
    }

    setFilterLawyer = (type, val) => {
        this.clearAllFilters(
            () => {
                this.setState({
                    filterBy: type,
                    filterLawyer: val
                }, () => this.getOrders(true, type, val))
            })
    }

    setFilterStatus = (type, val) => {
        let nextType = 'ALL';
        if (type === 'type') {
            nextType = val;
        }
        this.clearAllFilters(
            () => {
                this.setState({
                    filterBy: type,
                    filterStatus: val,
                    type: nextType,
                }, () => this.getOrders(true, type, val))
            })
    }

    setFilterType = (val) => {
        this.setState({
            type: val
        })
    }

    clearAllFilters = (thenMethod) => {
        this.setState({
            filterBy: null,
            filterName: null,
            filterLawyer: null,
            filterStatus: null,
            filterTransaction: null,
            type: null
        }, thenMethod)
    }

    getFilterValueBy = (filterBy) => {
        if (filterBy == undefined || filterBy == null) {
            filterBy = this.state.filterBy
        }
        let filterValue = null;

        switch (filterBy) {
            case 'search' : {
                filterValue = this.state.filterName;
                break;
            }
            case 'status' : {
                filterValue = this.state.filterStatus;
                break;
            }
            case 'lawyer' : {
                filterValue = this.state.filterLawyer;
                break;
            }
            case 'transaction' : {
                filterValue = this.state.filterTransaction;
                break;
            }
            case 'type' : {
                filterValue = this.state.type;
                break;
            }
            default: {
                break;
            }
        }
        return filterValue;
    }

    report = (startDate, endDate) => {
        let filterBy = this.state.filterBy;
        let filterValue = this.getFilterValueBy(filterBy);

        let params = {
            startDate: fDateTimeForBackend(startDate),
            endDate: fDateTimeForBackend(endDate),
            sortBy: this.state.orderBy,
            sortDirection: this.state.order,
            filterBy: filterBy,
            filterName: filterValue
        }
        LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.REPORT_BEING_CREATED);

        CommunicationService.generateReport(params)
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = "Report-" + Date.now() + ".pdf";
                    a.click();

                    LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.DOCUMENT_DOWNLOAD);
                });
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));

    }

    generateAWBs = (startDate, endDate) => {
        let params = {
            startDate: fDateTimeForBackend(startDate),
            endDate: fDateTimeForBackend(endDate)
        }
        LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.REPORT_BEING_CREATED);

        CommunicationService.generateAWBForAll(params)
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = "AWBs-" + Date.now() + ".pdf";
                    a.click();

                    LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.DOCUMENT_DOWNLOAD);
                });
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));

    }

    setRowsPerPage = (val) => {
        this.setState({rowsPerPage: val}, () => this.getOrders(true))
    }

    mapOrder = (order) => {
        let user = order.user;
        let userType = user.type === 'person' ? 'PF' : 'SRL';
        let name = user.firstName + ' ' + user.lastName;
        let idCard = order.documents.find(doc => doc.type === 'ID_CARD');
        let personWithId = order.documents.find(doc => doc.type === 'PERSON_WITH_ID');
        let bussinessContract = order.documents.find(doc => doc.type === 'BUSINESS_CONTRACT');
        let lawyerContract = order.documents.find(doc => doc.type === 'LAWYER_CONTRACT');
        let policeRecord = order.documents.find(doc => doc.type === 'POLICE_RECORD');
        let policeRecordTranslated = order.documents.find(doc => doc.type === 'POLICE_RECORD_TRANSLATED');
        let paymentProof = order.documents.find(doc => doc.type === 'PAYMENT_PROOF');
        let registrationCertificate = order.documents.find(doc => doc.type === 'REGISTRATION_CERTIFICATE');
        let anexa38 = order.documents.find(doc => doc.type === 'SRL_ANEXA_38');

        let orderStatus = order.status;
        let color = (STATUSES[orderStatus.toUpperCase()] || {}).color;
        let orderMapped = {
            id: order.id,
            type: order.type,
            communicationLogs: order.communicationLogs,
            avatarUrl: order.avatarUrl,
            name: name,
            firstName: user.firstName,
            lastName: user.lastName,
            cui: user.cui,
            userType: order.isFor === 'PERSON' ? 'PF' : 'SRL',
            transactionVerified: order.transactionVerified,
            awbGenerated: order.awbGenerated,
            translation: order.translation,
            translationLanguage: order.translationLanguage,
            regime: order.regime,
            legalized: order.legalized,
            apostila: order.apostila,
            address: order.address,
            delivery: order.delivery,
            status: orderStatus,
            createdAt: new Date(order.createdAt).toLocaleString(),
            price: order.price?.toString(),
            email: user.username,
            communicationEmail: user.communicationEmail,
            phoneNumber: user.phoneNumber,
            asigned: order.type,
            reason: order.reason,
            idCard: idCard,
            details: order.legalized + ' - ' + order.translation,
            personWithId: personWithId,
            bussinessContract: bussinessContract,
            lawyerContract: lawyerContract,
            policeRecord: policeRecord,
            policeRecordTranslated: policeRecordTranslated,
            paymentProof: paymentProof,
            registrationCertificate: registrationCertificate,
            anexa38: anexa38,
            color: color,
            documents: order.documents,
            browserCountry: order.browserCountry,
            browserIP: order.browserIP,
            problems: order.problems,
            fiscalDone: order.fiscalDone,
            paymentCode: order.paymentCode,
            refunded: order.refunded,
            user: user,
            origin: order.origin,
        };
        if (!this.state.isLawyer) {
            orderMapped['access'] = order.access.map(us => this.getUserWithNameFrom(us));
        }
        return orderMapped
    }

    componentDidMount() {
        let Auth = CommunicationService.getUser();
        let userType = CommunicationService.getUserType();
        let isModeratorOrGreater = Auth !== null && (userType === 'ROLE_ADMIN' || userType === 'ROLE_MODERATOR')
        this.setState({
            isLawyer: !isModeratorOrGreater
        }, () => {
            CommunicationService.getAllStatuses()
                .then((response) => {
                    this.setState({
                        statuses: response
                    })
                })
                .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
            if (isModeratorOrGreater) {
                CommunicationService.getAllLawyers()
                    .then((response) => {
                        this.setState({
                            lawyers: response.map(lw => this.getUserWithNameFrom(lw))
                        })
                        this.getOrders();
                    })
                    .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
            } else {
                this.setState({
                    tableHead: TABLE_HEAD_FOR_LAWYER
                })
                this.getOrders();
            }
        })
    }

    getUserWithNameFrom(user) {
        let userDetails = user.userDetails || null;
        let name = userDetails !== null ? userDetails.firstName + ' ' + userDetails.lastName : user.username;
        return {
            ...user,
            name: name
        }
    }

    getOrders(force = false, filterBy = this.state.filterBy, filterName = this.state.filterName) {
        if (force || this.state.orders.length === 0) {
            if (filterBy == undefined || filterBy == null) {
                filterBy = this.state.filterBy;
            }
            if (filterName == undefined || filterName == null) {
                filterName = this.getFilterValueBy(this.state.filterBy)
            }
            CommunicationService.getOrders(this.state.page, this.state.rowsPerPage, this.state.orderBy,
                this.state.order, filterBy, filterName, false)
                .then((response) => {
                    this.setState({
                        totalItems: response.totalItems,
                        totalPages: response.totalPages
                    })
                    this.setOrders(response.orders.map(order => this.mapOrder(order)))
                    this.setUrgentOrders(response.urgentOrders.map(order => this.mapOrder(order)))
                    this.setLaterAddedDeliveryOrders(response.laterAddedDeliveryOrders.map(order => this.mapOrder(order)))
                })
                .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
        }
    }

    downloadFile = (doc) => {
        CommunicationService.downloadFile(doc.url)
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = doc.name;
                    a.click();
                    LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.DOCUMENT_DOWNLOAD);
                });
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
    }

    showDocumentDetails = (order, doc) => {
        if (doc === undefined || doc === null || doc.internalName === undefined || doc.internalName === null) {
            alert("Error: call Cosmin")
            return;
        }
        let name = doc.internalName;
        let obj = {
            message: name + " - Copied to clipboard.",
            key: 'document_upload',
            severity: 'info'
        }

        navigator.permissions
            .query({name: "clipboard-write"})
            .then((result) => {
                if (result.state === "granted" || result.state === "prompt") {
                    navigator.clipboard.writeText(name).then(
                        () => {
                            LogicService.setAlertMessageAndSeverity(obj);
                        },
                        () => {
                            LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.CANNOT_COPY_TO_CLIPBOARD);
                        }
                    );
                }
            });

    }

    getPropIcon(text, value, key, icon, color) {
        let sx = {marginLeft: '0 !important', padding: "6px !important"};
        if (color !== undefined) {
            sx.background = color;
            sx.color = 'white';
        }
        return <ToggleButton value={value} key={key} sx={sx}>
            <Box component="img" src={icon} alt={text} title={text} sx={{width: 30}}/>
        </ToggleButton>;
    }

    getPropIconBox(iconOne, iconTwo) {
        return <div style={{border: "1px solid green", borderRadius: "5px", padding: "1px", margin: "0"}}>
            {
                iconOne == null ? null : this.getPropIcon(iconOne.text, iconOne.value, iconOne.key, iconOne.icon, iconOne.color)
            }
            {
                iconTwo == null ? null : this.getPropIcon(iconTwo.text, iconTwo.value, iconTwo.key, iconTwo.icon, iconTwo.color)
            }
        </div>
    }

    getReason(text, value, key, icon, problems) {
        let sx = {padding: "3px 5px !important"};
        if (problems === true) {
            sx.background = "red";
            sx.color = "white";
        }
        return <ToggleButton value={value} key={key} sx={sx}>
            <Typography variant={'body2'} sx={{margin: "0 !important"}} gutterBottom>{text}</Typography>
        </ToggleButton>;
    }

    render() {

        const ORDERLIST = this.state.orders;
        const page = this.state.page;
        const order = this.state.order;
        const selected = this.state.selected;
        const orderBy = this.state.orderBy;
        const filterName = this.state.filterName;
        const rowsPerPage = this.state.rowsPerPage;




        const handleRequestSort = (event, property) => {
            const isAsc = orderBy === property && order === 'asc';
            this.setOrder(isAsc ? 'desc' : 'asc');
            this.setOrderBy(property);
        };

        const handleSelectAllClick = (event) => {
            if (event.target.checked) {
                const newSelecteds = ORDERLIST.map((n) => n.name);
                this.setSelected(newSelecteds);
                return;
            }
            this.setSelected([]);
        };

        const handleClick = (event, name) => {
            const selectedIndex = selected.indexOf(name);
            let newSelected = [];
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, name);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selected.slice(0, selectedIndex),
                    selected.slice(selectedIndex + 1)
                );
            }
            this.setSelected(newSelected);
        };

        const handleChangePage = (event, newPage) => {
            this.setPage(newPage);
        };

        const handleChangeRowsPerPage = (event) => {
            this.setRowsPerPage(parseInt(event.target.value, 10));
            this.setPage(0);
        };

        const handleFilterByName = (text) => {
            this.setFilterName('search', text);
        };

        const filteredUsers = ORDERLIST;

        const isUserNotFound = filteredUsers.length === 0;

        const handleAccessChange = (orderId, userId, operation) => {
            CommunicationService.changeOrderAccess(orderId, operation, userId)
                .then((response) => {
                })
                .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
        }

        const updateOrder = (orderId, field, value) => {
            let orders = this.state.orders;
            let orderIndex = orders.findIndex(or => or.id === orderId);
            if (orderIndex === -1) {
                return;
            }
            orders[orderIndex][field] = value;
            this.setOrders(orders);
        }

        const uploadDocument = (event, orderId, objectKey, field) => {
            let file = event.target.files[0];
            CommunicationService.addDocumentToOrder(orderId, objectKey, file)
                .then((response) => {
                    updateOrder(orderId, field, response);
                    LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.DOCUMENT_UPLOAD);
                })
                .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
        }

        const documentIsDeleted = (document) => {
            if (document === undefined || document === null
                || document.uploadedDateTime === undefined || document.uploadedDateTime === null) {
                return false;
            }
            let docDate = new Date(document.uploadedDateTime);
            if (docDate.getFullYear() >= 2023 && (docDate.getMonth() + 1) > 2) {
                return false;
            }
            let daysBetweenNowAndThen = ((new Date()).getTime() - docDate.getTime()) / (1000 * 3600 * 24);
            return daysBetweenNowAndThen >= 30;
        }

        let URGENT_ORDERS = this.state.urgentOrders;
        let urgentOrdersExist = URGENT_ORDERS.length > 0;

        let LATER_ADDED_DELIVERY_ORDERS = this.state.laterAddedDeliveryOrders;
        let laterAddedDeliveryOrdersExist = LATER_ADDED_DELIVERY_ORDERS.length > 0;

        return (
            <Page title="Cazier Judiciar Online – Eliberare Cazier Fara Deplasari">
                <Container maxWidth={"1600px"} >
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            Orders
                        </Typography>
                    </Stack>


                    {
                        this.state.isLawyer ? null : <Card sx={{marginBottom: '15px'}} >

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon style={{color: 'white'}}/>}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={laterAddedDeliveryOrdersExist ? {color: "white", background: 'blue'} : {}}
                                >
                                    <Typography>Later delivery added orders ({LATER_ADDED_DELIVERY_ORDERS.length})</Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{background: '#ffebee'}}>
                                    <Scrollbar>
                                        <TableContainer sx={{minWidth: 800}}>
                                            <Table>
                                                <TableBody>
                                                    {LATER_ADDED_DELIVERY_ORDERS.map((row) => this.mapOrderTo(row, selected, handleClick, handleAccessChange, documentIsDeleted, uploadDocument, true))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Scrollbar>
                                </AccordionDetails>
                            </Accordion>
                        </Card>
                    }

                    <Card sx={{marginBottom: '15px'}} >

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon style={{color: 'white'}}/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                sx={urgentOrdersExist ? {color: "white", background: 'red'} : {}}
                            >
                                <Typography>Urgent orders ({URGENT_ORDERS.length})</Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{background: '#ffebee'}}>
                                <Scrollbar>
                                    <TableContainer sx={{minWidth: 800}}>
                                        <Table>
                                            <TableBody>
                                                {URGENT_ORDERS.map((row) => this.mapOrderTo(row, selected, handleClick, handleAccessChange, documentIsDeleted, uploadDocument, true))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Scrollbar>
                            </AccordionDetails>
                        </Accordion>
                    </Card>

                    <Card sx={{}}>
                        <ReportToolbar isLawyer={this.state.isLawyer} handleReportClick={this.report}
                                       handleAWBClick={this.generateAWBs}/>

                        <UserListToolbar
                            sx={{width: 500}}
                            numSelected={selected.length}
                            filterName={filterName}
                            onFilterName={handleFilterByName}
                            isLawyer={this.state.isLawyer}

                            lawyers={this.state.lawyers}
                            lawyerSelected={this.state.filterLawyer}
                            handleLawyerChange={(event) => this.setFilterLawyer('lawyer', event.target.value)}

                            statuses={this.state.statuses}
                            statusSelected={this.state.filterStatus}
                            handleStatusChange={(event) => this.setFilterStatus('status', event.target.value)}

                            orderTypeSelected={this.state.type}
                            handleOrderTypeChange={(event) => this.setFilterStatus('type', event.target.value)}

                            transactions={this.state.transactions}
                            transactionSelected={this.state.filterTransaction}
                            handleTransactionChange={(event) => this.setFilterStatus('transaction', event.target.value)}
                        />

                        <Scrollbar>
                            <TableContainer sx={{minWidth: 800}}>
                                <Table>
                                    <UserListHead
                                        order={order}
                                        orderBy={orderBy}
                                        headLabel={this.state.tableHead}
                                        rowCount={this.state.totalItems}
                                        numSelected={selected.length}
                                        onRequestSort={handleRequestSort}
                                        onSelectAllClick={handleSelectAllClick}
                                    />
                                    <TableBody>
                                        {filteredUsers.map((row) => this.mapOrderTo(row, selected, handleClick, handleAccessChange, documentIsDeleted, uploadDocument))}
                                    </TableBody>
                                    {isUserNotFound && (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="center" colSpan={6} sx={{py: 3}}>
                                                    <SearchNotFound searchQuery={filterName}/>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )}
                                </Table>
                            </TableContainer>
                        </Scrollbar>

                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={this.state.totalItems}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Card>
                </Container>
            </Page>
        );
    }

    mapOrderTo(row, selected, handleClick, handleAccessChange, documentIsDeleted, uploadDocument, urgent = false) {
        const {
            id,
            type,
            name,
            userType,
            address,
            price,
            status,
            createdAt,
            transactionVerified,
            awbGenerated,
            legalized,
            apostila,
            delivery,
            access,
            reason,
            idCard,
            lawyerContract,
            registrationCertificate,
            color,
            personWithId,
            bussinessContract,
            policeRecord,
            policeRecordTranslated,
            paymentProof,
            anexa38,
            regime,
            translation,
            translationLanguage,
            browserCountry,
            browserIP,
            fiscalDone,
            problems,
            paymentCode,
            refunded,
            user, origin
        } = row;
        const isForeignUser = user != undefined && user != null && user.fatherName != null && user.motherName != null;

        const isItemSelected = selected.indexOf(name) !== -1;

        let deliveryIcon = null;
        if (delivery === 'ELECTRONIC') {
            deliveryIcon = 'email.png';
        } else if (delivery === 'ADDRESS') {
            deliveryIcon = 'fast-delivery.png'
        }

        let regimeIcon = null;
        if (regime === 'URGENT') {
            regimeIcon = 'calendar_1_day.png';
        } else if (regime === 'STANDARD') {
            regimeIcon = 'calendar_5_day.png'
        } else if (regime === 'ADDRESS_FOREIGN_UE') {
            regimeIcon = 'calendar_10_day.png'
        }

        let translationCountry = null;
        if (translationLanguage !== undefined && translationLanguage !== null && translationLanguage !== 'Romania') {
            translationCountry = Languages.getLanguageByName(translationLanguage);
            if (translationCountry === null) {
                translationCountry = Countries.getCountryByName(translationLanguage);
                if (translationCountry == undefined) {
                    translationCountry = null;
                }
            }
        }

        let deliveryCountry = null;
        if (delivery !== undefined && delivery !== null && delivery === 'ADDRESS' && address !== undefined && address !== null) {
            deliveryCountry = Countries.getCountryByName(address.country);
        }

        return this.getOrderRow(id, type, isItemSelected, handleClick, name, userType, color, status, transactionVerified, price, createdAt, access, handleAccessChange, documentIsDeleted, idCard, personWithId, bussinessContract, registrationCertificate, anexa38, lawyerContract, policeRecord, uploadDocument, policeRecordTranslated, translationCountry, apostila, paymentProof, reason, problems, legalized, regime, regimeIcon, delivery, deliveryIcon, awbGenerated, address, deliveryCountry, row, urgent, isForeignUser, refunded, origin);
    }

    getOrderRow(id, type, isItemSelected, handleClick, name, userType, color, status, transactionVerified, price, createdAt, access, handleAccessChange, documentIsDeleted, idCard, personWithId, bussinessContract, registrationCertificate, anexa38, lawyerContract, policeRecord, uploadDocument, policeRecordTranslated, translationCountry, apostila, paymentProof, reason, problems, legalized, regime, regimeIcon, delivery, deliveryIcon, awbGenerated, address, deliveryCountry, row, urgent = false, isForeignUser = false, refunded = false, origin) {

        let logs = row.communicationLogs;
        let smsLogs = logs.filter(l => l.type == 'SMS');
        let emailLogs = logs.filter(l => l.type == 'EMAIL');

        let smsRoPoliceRecordTexts = [
            "Cazierul dvs în limba română este disponibil pe email și pe contul dvs de pe site. Urmează să primiți și traducerea în scurt timp.",
            "postilarea inclusiv supralegalizarea",
            "Cazierul dvs. este gata de descărcare."
        ]

        let smsForeignPoliceRecordTexts = [
            "Cazierul dvs. tradus si apostilat este disponibil pe email și pe contul dvs de pe site.",
            "Cazierul dvs tradus este disponibil pe email și pe contul dvs de pe site.",
            "Cazierul dvs apostilat este disponibil pe email și pe contul dvs de pe site."
        ]


        let emailRoPoliceRecordTexts = [
            "Cazierul dvs în limba română este disponibil",
            "Vești bune! Cazierul dvs. este gata.",
            "Vești bune! Certificatul  dvs. de integritate comportamentală este gata."
        ]

        let emailForeignPoliceRecordTexts = [
            "Cazierul dvs tradus este disponibil",
            "Cazierul dvs apostilat este disponibil",
            "Cazierul dvs tradus si apostilat este disponibil"
        ]

        let policeRecordSmsExist = smsLogs.filter(log => smsRoPoliceRecordTexts.find(text => log.message.includes(text))).length;
        let policeRecordEmailExist = emailLogs.filter(log => emailRoPoliceRecordTexts.find(text => log.subject.includes(text))).length;

        let policeRecordTranslatedSmsExist = smsLogs.filter(log => smsForeignPoliceRecordTexts.find(text => log.message.includes(text))).length;
        let policeRecordTranslatedEmailExist = emailLogs.filter(log => emailForeignPoliceRecordTexts.find(text => log.subject.includes(text))).length;


        let policeRecordCommunicationMissing = (policeRecordSmsExist + policeRecordEmailExist) > 0;
        let policeRecordForeignCommunicationMissing = (policeRecordTranslatedSmsExist + policeRecordTranslatedEmailExist) > 1;

        console.log("\n" + id)
        console.log(smsLogs)
        console.log(smsLogs.filter(log => smsRoPoliceRecordTexts.find(text => log.message.includes(text))))
        console.log(emailLogs)
        console.log(emailLogs.filter(log => emailRoPoliceRecordTexts.find(text => log.subject.includes(text))))


        if (apostila === false && (translationCountry === null || translationCountry.code.toLowerCase() === 'ro')) {
            policeRecordForeignCommunicationMissing = true;
        }

        let originParsed = origin.replace("_RO", "");
        let lineColor = originParsed == "CAZIERE" ? "#CCFAFF" : "#fffdfa";
        return (
            <TableRow
                hover
                key={id}
                tabIndex={-1}
                role="checkbox"
                selected={isItemSelected}
                aria-checked={isItemSelected}
                sx={type === 'COMP_CERTIFICATE' ? {background: 'rgba(0, 0, 255, 0.1)'} : {}}
            >
                <TableCell padding="checkbox">
                    {
                        urgent ? null : <Checkbox
                            checked={isItemSelected}
                            onChange={(event) => handleClick(event, name)}
                        />
                    }
                </TableCell>
                <TableCell align="left" sx={{background: lineColor}}>{id}</TableCell>
                <TableCell align="left" sx={{background: lineColor, minWidth: '150px'}}>{isForeignUser ? <b>{name}</b> : name}</TableCell>
                <TableCell align="left">{userType}</TableCell>
                <TableCell align="left" sx={{fontSize: '0.5 rem'}}>
                    <Label
                        variant="ghost"
                        color={color}
                        sx={{height: '35px', fontSize: '10px !important'}}
                    >
                        {sentenceCase(status)}
                    </Label>
                </TableCell>

                {
                    this.state.isLawyer ? null :
                        <TableCell align="left">
                            <Label
                                variant="ghost"
                                color={transactionVerified ? 'success' : 'error'}
                            >
                                {sentenceCase(price)}
                            </Label>
                        </TableCell>

                }
                <TableCell align="left">{createdAt}</TableCell>
                {
                    this.state.isLawyer ? null :
                        <TableCell align="left">
                            <AccessSelect options={this.state.lawyers}
                                          selected={access}
                                          onChange={(userId, operation) => handleAccessChange(id, userId, operation)}/>
                        </TableCell>

                }
                <TableCell align="left">
                    <IconButton
                        onClick={() => documentIsDeleted(idCard) ? this.showDocumentDetails(id, idCard) : this.downloadFile(idCard)}
                        sx={idCard !== undefined && idCard.hasProblems ? {
                            background: "red",
                            color: "white"
                        } : null}
                        variant="contained"
                        disabled={idCard == null}>

                        <Icon icon={documentIsDeleted(idCard) ? deletedFileOutlined : downloadOutlined} width={24}
                              height={24}/>
                    </IconButton>
                </TableCell>
                {
                    this.state.isLawyer ? null :
                        <TableCell align="left">
                            <IconButton
                                sx={personWithId !== undefined && personWithId.hasProblems ? {
                                    background: "red",
                                    color: "white"
                                } : null}
                                onClick={() => documentIsDeleted(personWithId) ? this.showDocumentDetails(id, personWithId) : this.downloadFile(personWithId)}
                                disabled={personWithId == null}>
                                <Icon icon={documentIsDeleted(personWithId) ? deletedFileOutlined : downloadOutlined}
                                      width={24} height={24}/>
                            </IconButton>
                        </TableCell>

                }
                <TableCell align="left">
                    <IconButton
                        sx={bussinessContract !== undefined && bussinessContract.hasProblems ? {
                            background: "red",
                            color: "white"
                        } : null}
                        onClick={() => documentIsDeleted(bussinessContract) ? this.showDocumentDetails(id, bussinessContract) : this.downloadFile(bussinessContract)}
                        disabled={bussinessContract == null}>
                        <Icon icon={documentIsDeleted(bussinessContract) ? deletedFileOutlined : downloadOutlined}
                              width={24} height={24}/>
                    </IconButton>
                </TableCell>
                <TableCell align="left">
                    <IconButton
                        sx={registrationCertificate !== undefined && registrationCertificate.hasProblems ? {
                            background: "red",
                            color: "white"
                        } : null}
                        onClick={() => documentIsDeleted(registrationCertificate) ? this.showDocumentDetails(id, registrationCertificate) : this.downloadFile(registrationCertificate)}
                        disabled={registrationCertificate == null}>
                        <Icon icon={documentIsDeleted(registrationCertificate) ? deletedFileOutlined : downloadOutlined}
                              width={24} height={24}/>
                    </IconButton>
                </TableCell>
                {
                    <TableCell align="left">
                        <IconButton
                            sx={anexa38 !== undefined && anexa38.hasProblems ? {
                                background: "red",
                                color: "white"
                            } : null}
                            onClick={() => documentIsDeleted(anexa38) ? this.showDocumentDetails(id, anexa38) : this.downloadFile(anexa38)}
                            disabled={anexa38 == null}>
                            <Icon icon={documentIsDeleted(anexa38) ? deletedFileOutlined : downloadOutlined} width={24}
                                  height={24}/>
                        </IconButton>
                    </TableCell>
                }
                {
                    this.state.isLawyer ? null : <TableCell align="left">
                        <IconButton
                            sx={lawyerContract !== undefined && lawyerContract.hasProblems ? {
                                background: "red",
                                color: "white"
                            } : null}
                            onClick={() => documentIsDeleted(lawyerContract) ? this.showDocumentDetails(id, lawyerContract) : this.downloadFile(lawyerContract)}
                            disabled={lawyerContract == null}>
                            <Icon icon={documentIsDeleted(lawyerContract) ? deletedFileOutlined : downloadOutlined}
                                  width={24} height={24}/>
                        </IconButton>
                    </TableCell>
                }
                {
                    this.state.isLawyer ? null : <TableCell align="left">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                                {
                                    policeRecord == null ?
                                        <label
                                            htmlFor={`contained-button-file_${id}_policeRecord`}>
                                            <input accept="image/*, application/pdf"
                                                   id={`contained-button-file_${id}_policeRecord`}
                                                   onChange={(event => uploadDocument(event, id, 'police_record', 'policeRecord'))}
                                                   multiple type="file"
                                                   style={{display: "none"}}/>
                                            <IconButton variant="contained"
                                                        color="primary"
                                                        id={`contained-button-show-file_${id}_policeRecord`}
                                                        component="span"
                                                        sx={{marginTop: "10px"}}><FileUploadIcon/>
                                            </IconButton>
                                        </label>
                                        :
                                        <IconButton
                                            onClick={() => this.downloadFile(policeRecord)}>
                                            <Icon icon={downloadOutlined} width={24}
                                                  height={24}/>
                                        </IconButton>
                                }
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                {
                                    policeRecord != null ?

                                        <label
                                            htmlFor={`contained-button-file_${id}_policeRecord`}>
                                            <input accept="image/*, application/pdf"
                                                   id={`contained-button-file_${id}_policeRecord`}
                                                   onChange={(event => uploadDocument(event, id, 'police_record', 'policeRecord'))}
                                                   multiple type="file"
                                                   style={{display: "none"}}/>
                                            <IconButton variant="contained"
                                                        color={policeRecordCommunicationMissing ? "primary": "secondary"}
                                                        sx={{border: '0.5px solid black', background: policeRecordCommunicationMissing ? 'transparent': 'black'}}
                                                        id={`contained-button-show-file_${id}_policeRecord`}
                                                        component="span"><FileUploadIcon/>
                                            </IconButton>
                                        </label>
                                        : null
                                }
                            </Grid>
                        </Grid>

                    </TableCell>
                }
                {
                    this.state.isLawyer ? null : <TableCell align="left">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                                {
                                    policeRecordTranslated == null ?
                                        <label
                                            htmlFor={`contained-button-file_${id}_policeRecordTranslated`}>
                                            <Input accept="image/*"
                                                   id={`contained-button-file_${id}_policeRecordTranslated`}
                                                   onChange={(event => uploadDocument(event, id, 'police_record_translated', 'policeRecordTranslated'))}
                                                   multiple type="file"
                                                   sx={{display: "none"}}/>
                                                    <IconButton variant="contained"
                                                                color="primary"
                                                                disabled={translationCountry === null && !apostila}
                                                                id={`contained-button-show-file_${id}_policeRecordTranslated`}
                                                                sx={{marginTop: "10px"}}
                                                                component="span"
                                                    ><FileUploadIcon/>
                                            </IconButton>
                                        </label>
                                        :
                                        <IconButton
                                            disabled={translationCountry === null && !apostila}
                                            onClick={() => this.downloadFile(policeRecordTranslated)}>
                                            <Icon icon={downloadOutlined} width={24}
                                                  height={24}/>
                                        </IconButton>
                                }
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                {
                                    policeRecordTranslated != null ?
                                        <label
                                            htmlFor={`contained-button-file_${id}_policeRecordTranslated`}>
                                            <Input accept="image/*"
                                                   id={`contained-button-file_${id}_policeRecordTranslated`}
                                                   onChange={(event => uploadDocument(event, id, 'police_record_translated', 'policeRecordTranslated'))}
                                                   multiple type="file"
                                                   sx={{display: "none"}}/>
                                            <IconButton variant="contained"
                                                        color={policeRecordForeignCommunicationMissing ? "primary": "secondary"}
                                                        disabled={translationCountry === null && !apostila}
                                                        id={`contained-button-show-file_${id}_policeRecordTranslated`}
                                                        component="span"
                                                        sx={{border: '0.5px solid black', background: policeRecordForeignCommunicationMissing ? 'transparent': 'black' }}
                                            ><FileUploadIcon/>
                                            </IconButton>
                                        </label>
                                        :
                                        null
                                }
                            </Grid>
                        </Grid>

                    </TableCell>
                }
                {
                    this.state.isLawyer ? null : <TableCell align="left">
                        <IconButton
                            sx={paymentProof !== undefined && paymentProof.hasProblems ? {
                                background: "red",
                                color: "white"
                            } : null}
                            onClick={() => documentIsDeleted(paymentProof) ? this.showDocumentDetails(id, paymentProof) : this.downloadFile(paymentProof)}
                            disabled={paymentProof == null}>
                            <Icon icon={documentIsDeleted(paymentProof) ? deletedFileOutlined : downloadOutlined}
                                  width={24}
                                  height={24}/>
                        </IconButton>
                    </TableCell>
                }

                <TableCell align="left" sx={{width: '100%', maxWidth: '600px'}}>
                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}
                           alignSelf={"center"}>
                        {
                            reason === undefined || reason === null ? null :
                                this.getReason(reason, null, null, null, problems)
                        }
                    </Stack>
                    <Stack
                        direction={{
                            xs: 'row',
                            sm: 'row',
                            flexWrap: "wrap",
                            marginTop: '2px'
                        }}
                        spacing={2} alignSelf={"center"}>
                        {
                            translationCountry === null ? null :
                                this.getPropIconBox({
                                    text: "Traducere",
                                    value: "translation",
                                    key: "translation",
                                    icon: "/static/illustrations/translation.png"
                                }, {
                                    text: translationCountry.label,
                                    value: "translationCountry",
                                    key: "translationCountry",
                                    icon: `https://flagcdn.com/w20/${translationCountry.code.toLowerCase()}.png`
                                })
                        }
                        {
                            !legalized ? null :
                                this.getPropIcon('Traducere legalizată', 'legalized', 'legalized',
                                    "/static/illustrations/legalized.png")
                        }
                        {
                            regime === undefined || regime === null || regimeIcon === null ? null :
                                this.getPropIcon('Regim', 'regime', 'regime',
                                    "/static/illustrations/" + regimeIcon)
                        }
                        {
                            !apostila ? null :
                                this.getPropIcon('Apostilă Haga', 'apostila', 'apostila',
                                    "/static/illustrations/stamp.png")
                        }
                        {
                            (delivery === undefined || delivery === null || deliveryIcon === null || delivery === 'ELECTRONIC') ? null :
                                this.getPropIconBox({
                                    text: "Livrare",
                                    value: "delivery",
                                    key: "delivery",
                                    icon: "/static/illustrations/" + deliveryIcon,
                                    color: awbGenerated ? 'lightGreen' : (address != undefined && address != null && address.manuallyCompleted ? 'red' : undefined)
                                }, deliveryCountry == null ? null : {
                                    text: "Tara livrare",
                                    value: "delivery_country",
                                    key: "delivery_country",
                                    icon: `https://flagcdn.com/w20/${deliveryCountry.code.toLowerCase()}.png`
                                })
                        }
                        {
                            (delivery === undefined || delivery === null || deliveryIcon === null || delivery !== 'ELECTRONIC') ? null :
                                this.getPropIcon('Livrare', 'delivery', 'delivery', "/static/illustrations/" + deliveryIcon, awbGenerated ? 'lightGreen' : undefined)
                        }
                    </Stack>
                </TableCell>
                <TableCell align="right">
                    <OrderMoreMenu row={row} isLawyer={this.state.isLawyer}/>
                </TableCell>
            </TableRow>
        );
    }
}
